/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.has-error .form-control,
.has-success .form-control {
    border-width: 2px;
}

.has-error > .input-group {
    border: 1px solid #f32020;
}

.has-success > .input-group {
    border: 1px solid #00b900;
}

.field-error {
    display: block;
    font-size: 80%;
    width: 100%;
    color: #f32020;
}

.font-blue {
    color: blue;
}

.link {
    color: Blue !important;
    cursor: pointer;
    text-decoration: underline;
}

    .link:hover {
        font-weight: bold;
    }



a:hover {
    &.underline

{
    text-decoration: underline !important;
}
}
.part-photo-list{
    cursor:pointer;
}
.part-name-textarea {
    border: none;
    border: none;
    width: 100%;
    /* height:150px !important; */
    -webkit-box-sizing: border-box; /* <=iOS4, <= Android  2.3 */
    -moz-box-sizing: border-box; /* FF1+ */
    box-sizing: border-box; /* Chrome, IE8, Opera, Safari 5.1*/
}
    .part-name-textarea:focus {
        border-width: 2px;
        border-radius: 2px;
    }

    .r-180 {
        -webkit-transition: all 1s;
        transition: all 1s;       
          transform: rotate(180deg);         
      }

      .box-border {
        border: 2px solid grey;
        border-radius: 4px;
        align-items: center;
        flex-wrap: wrap;
        margin-right: auto;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 1rem;
      }